import { Subject } from "rxjs";

/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { LoggerInterface } from "@interfaces/LoggerInterface";

export interface CatchedResponse {
    status: number;
}

export interface CatchedRequest {
    url: string;
    method: string;
    body:
        | Blob
        | Document
        | BufferSource
        | FormData
        | URLSearchParams
        | ReadableStream<Uint8Array>
        | string
        | null
        | undefined;
    response: Promise<CatchedResponse>;
}

type PatchedXMLHttpRequest = XMLHttpRequest & {
    _meta: {
        url: string;
        method: string;
        responsePromise: Promise<CatchedResponse>;
    };
};

class FetchAndXHRPatcher {
    requests$ = new Subject<CatchedRequest>();
    constructor(private readonly logger: LoggerInterface) {}
    patch() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        // patch fetch
        const originalFetch = window.fetch;
        window.fetch = (
            input: RequestInfo,
            init?: RequestInit
        ): Promise<Response> => {
            // TODO: we must delete call of method toString(), because Request.url must be string, but growave legacy patcher set URL to Request
            const url =
                input instanceof Request
                    ? input.url.toString()
                    : input.toString();
            const body =
                (input instanceof Request ? init?.body : undefined) ||
                init?.body;
            const method =
                (input instanceof Request ? init?.method : undefined) ||
                init?.method ||
                "GET";
            const responsePromise = originalFetch(input, init);
            const catchedResponsePromise = responsePromise.then((response) => {
                return {
                    status: response.status,
                };
            });
            const catchedRequest = {
                url,
                method,
                body,
                response: catchedResponsePromise,
            };
            _this.logger.debug("FetchAndXHRPatcher catch fetch-request", {
                catchedRequest,
            });
            this.requests$.next(catchedRequest);
            return responsePromise;
        };
        // patch XMLHttpReques
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const originalOpen = window.XMLHttpRequest.prototype.open;
        function open(method: string, url: string): void;
        function open(
            this: PatchedXMLHttpRequest,
            method: string,
            url: string,
            async?: boolean,
            username?: string | null,
            password?: string | null
        ): void {
            const responsePromise = new Promise<CatchedResponse>(
                (resolve, reject) => {
                    const loadHandler = () => {
                        this.removeEventListener("load", loadHandler);
                        resolve({
                            status: this.status,
                        });
                    };
                    this.addEventListener("load", loadHandler);
                    const errorHandler = () => {
                        this.removeEventListener("error", errorHandler);
                        reject();
                    };
                    this.addEventListener("error", errorHandler);
                }
            );
            this._meta = {
                url: url,
                method: method,
                responsePromise,
            };
            if (async) {
                originalOpen.apply(this, [
                    method,
                    url,
                    async,
                    username,
                    password,
                ]);
            } else {
                (originalOpen as (method: string, url: string) => void).apply(
                    this,
                    [method, url]
                );
            }
        }
        window.XMLHttpRequest.prototype.open = open;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const originalSend = window.XMLHttpRequest.prototype.send;
        window.XMLHttpRequest.prototype.send = function (
            this: PatchedXMLHttpRequest,
            data
        ) {
            if (this._meta) {
                const catchedRequest: CatchedRequest = {
                    url: this._meta.url,
                    method: this._meta.method,
                    body: data,
                    response: this._meta.responsePromise,
                };
                _this.logger.debug("FetchAndXHRPatcher catch xhr-request", {
                    catchedRequest,
                });
                _this.requests$.next(catchedRequest);
            }
            originalSend.apply(this, [data]);
        };
        this.logger.info("FetchAndXHRPatcher pathed fetch and XMLHttpRequest");
    }
}

export default FetchAndXHRPatcher;
