import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import xss from "xss";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import HTMLSanitizerInterface, {
    HTMLSanitizeWhitelist,
} from "./HTMLSanitizerInterface";

class XSSHTMLSanitizer implements HTMLSanitizerInterface {
    constructor(
        private readonly logger: LoggerInterface,
        private readonly whiteList?: HTMLSanitizeWhitelist
    ) {}
    handleIgnoreAttribute(
        tag: string,
        name: string,
        value: string,
        isWhiteAttr: boolean
    ) {
        this.logger.warn("ignored attribute", {
            tag,
            name,
            value,
            isWhiteAttr,
        });
    }
    handleIgnoreTag(tag: string, html: string, options: any) {
        this.logger.warn("ignored tag", {
            tag,
            html,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            options,
        });
    }

    sanitize(html: string, whiteList?: HTMLSanitizeWhitelist) {
        return xss(html, {
            whiteList: whiteList || this.whiteList,
            onIgnoreTag: this.handleIgnoreTag.bind(this),
            onIgnoreTagAttr: this.handleIgnoreAttribute.bind(this),
        });
    }
    sanitize$(html: Observable<string>, whiteList?: HTMLSanitizeWhitelist) {
        return html.pipe(
            map((html) => {
                const result = xss(html, {
                    whiteList: whiteList || this.whiteList,
                    onIgnoreTag: this.handleIgnoreTag.bind(this),
                    onIgnoreTagAttr: this.handleIgnoreAttribute.bind(this),
                });
                return result;
            })
        );
    }
}

export default XSSHTMLSanitizer;
